import { inject, Injectable, Injector } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize, switchMap, share, delay } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { HelperService } from "@mypxplat/xplat/core";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  injector = inject(Injector);
  private inFlightRequests = new Map<string, Observable<HttpEvent<any>>>();
  private _authService: AuthService;
  constructor(private helperService: HelperService) {}

  get authService() {
    if (!this._authService) {
      this._authService = this.injector.get(AuthService);
    }
    return this._authService;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestIdentifier = this.getRequestIdentifier(request);
    if (this.inFlightRequests.has(requestIdentifier)) {
      return this.inFlightRequests.get(requestIdentifier);
    }

    let handledRequest: Observable<HttpEvent<any>>;

    if (request.headers.get("X-USER-TOKEN")) {
      var unixTimeStamp = Math.floor(new Date().getTime() / 1000);
      let isLegacyToken = request.headers.get("X-USER-TOKEN").length < 50;
      let isLoggingOut = request.url.includes("logout");
      if (isLegacyToken || isLoggingOut || (this.helperService.tokenExpiry && this.helperService.tokenExpiry > unixTimeStamp)) {
        handledRequest = next.handle(request);
      } else {
        handledRequest = this.authService.authenticate(request).pipe(
          switchMap((authenticatedRequest) => {
            return next.handle(authenticatedRequest);
          })
        );
      }
    } else {
      // Not checking auth because no token was passed to this request.
      handledRequest = next.handle(request);
    }

    // Using share() to multicast the observable.
    // this code means that if component A and component B both trigger a request to the same endpoint,
    // the request will only be sent once, and the response will be shared between the two components.
    const sharedRequestObservable = handledRequest.pipe(
      share(),
      delay(0),
      finalize(() => {
        this.inFlightRequests.delete(requestIdentifier);
      })
    );
    this.inFlightRequests.set(requestIdentifier, sharedRequestObservable);

    return sharedRequestObservable;
  }

  private getRequestIdentifier(req: HttpRequest<any>): string {
    return `${req.method} ${req.url}`;
  }
}
